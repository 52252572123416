import { motion, useMotionValue, useTransform } from "framer-motion";
import React from "react";
import blur from "../images/smoke.jpg";
const backgroundBlur = {
  backgroundImage: "url(" + blur + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "bottom",
};

function Hobbies() {
  return (
    <div
      id="hobbies"
      className="h-screen md:p-20 flex flex-row justify-between text-white"
      style={backgroundBlur}
    >
      <div className="flex-col">
      <motion.div
        whileInView={{
          scale: 1,
          translateX: 0,
          transition: {
            delay: 0.1,
            duration: 0.5,
          },
        }}
        initial={{ scale: 0.5, translateX: -100 }}
        exit={{ scale: 0.5, translateX: -100 }}
        className="w-60 bg-white bg-opacity-10 p-2 border-2 border-blue-200 rounded-xl border-opacity-30 backdrop-blur-3xl"
      >
      Hello there
      </motion.div>
      </div>
      <motion.div 
      className="h-40 w-60 bg-white bg-opacity-10 border-2 border-blue-200 rounded-xl border-opacity-30 backdrop-blur-3xl">

      </motion.div>
    </div>
  );
}

export default Hobbies;
