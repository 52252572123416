import { motion } from "framer-motion";
import React from "react";
import forest from "../images/forest3.jpg";

function Homepage() {
  const backgroundBlur = {
    backgroundImage: "url(" + forest + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom 50px",
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: 1
      }
    }
  }

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <>
      <div
        id="home"
        className="h-screen pt-48 md:pt-56 md:pl-20 p-5 "
        style={backgroundBlur}
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="text-2xl font-black text-neutral-400 z-20 selection:text-green-200 selection:bg-neutral-800"
          style={{ fontFamily: "Lato" }}
        >
          <motion.span className="text-6xl md:text-8xl text-neutral-200" variants={item}>
            Hello there!
          </motion.span>
          <br />
          <motion.span className="text-neutral-300 text-2xl md:text-3xl bg-neutral-700" variants={item}>
            I'm
            <motion.a
              variants={item}
              className="select-none italic"
              href="/about"
              whileHover={{
                fontSize: "50px",
                color: "rgb(187 247 208)",
              }}
            >
              {" "}
              Siddharth
            </motion.a>
            ...
          </motion.span>
          <motion.p className="mt-4" variants={item}>
            Just a <motion.span className="underline underline-offset-4" variants={item}>guy</motion.span>{" "}
            making random{" "}
            <motion.span
              variants={item}
              className="bg-black font-thin"
              style={{ fontFamily: "JetBrains Mono", fontSize: 18 }}
            >
              <motion.span variants={item} className="text-blue-100">navigator</motion.span>.
              <motion.span variants={item} className="text-yellow-100">push(</motion.span>
              <motion.a
                href="/projects"
                variants={item}
                whileHover={{ color: "rgb(187 247 208)", fontSize: "30px" }}
                className="select-none italic"
                onClick={() => {
                  window.navigator.vibrate(100);
                }}
              >
                "stuff"
              </motion.a>
              <span className="text-yellow-100">)</span>
            </motion.span>
          </motion.p>
        </motion.div>
        <p
          style={{ marginLeft: "80%", marginTop: "30vh" }}
          className="text-neutral-400 selection:text-green-200 selection:bg-black text-xs"
        >
          Why the hell background is a <b>forest</b>? (⊙_⊙;) I don't know.
        </p>
      </div>
    </>
  );
}

export default Homepage;
