import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
function SideBarButton(props) {
  return (
    <a href={props.link}>
      <motion.div
        className="flex flex-col"
        whileHover={{
          scale: 1.2,
          transition: { duration: 0.25 },
        }}
        whileTap={{
          scale: 0.8,
          rotate: -10,
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} className="p-2" />
      </motion.div>
    </a>
  );
}
SideBarButton.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
export default SideBarButton;
