import React from 'react'

function Contact() {
  return (
    <div      
    id="contact"
    className="h-screen md:p-20 flex flex-row justify-between text-white">
      Contact
    </div>
  )
}

export default Contact