import { motion } from "framer-motion";
import React from "react";
import Logo from "./utility/Logo";
import SideBarButton from "./utility/SideBarButton";
import PropTypes from "prop-types";

const bgtext = {
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(225,225,225, 0.1)" /* Black w/opacity/see-through */,
  color: "white",
  padding: "10px",
  textAlign: "center",
};
const bgLogo = {
  backdropFilter: "blur(10px)",
  backgroundColor: "rgba(225,225,225, 0.3)" /* Black w/opacity/see-through */,
  color: "black",
  padding: "10px",
  textAlign: "center",
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};
function Sidebar(props) {
  var sectionName =  " . . "
  if(props.viewPortYScroll < 0.333) {
    sectionName = ". ."
  }else if(props.viewPortYScroll < 0.666){
    sectionName = "Hobbies"
  }else if(props.viewPortYScroll < 1){
    sectionName = "Weakness"
  }else{
    sectionName = "Contact"
  }
  return (
    <div className="fixed top-0 left-0 h-screen">
      <motion.div
        className="fixed top-0 md:left-10 z-30 rounded-sm shadow-lg hover:shadow-xl hover:rounded-md"
        style={bgLogo}
        whileTap={{
          opacity: 0.5,
        }}
      >
        <Logo name={sectionName} />
      </motion.div>

      {props.viewPortYScroll > 0.3 ? (
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="fixed top-1/2 left-5 z-30 rounded-lg"
          style={bgtext}
        >
          <SideBarButton name="Home" link="#home" />
        </motion.div>
      ) : (
        <></>
      )}
    </div>
  );
}

Sidebar.propTypes = {
  viewPortYScroll: PropTypes.number.isRequired,
};

export default Sidebar;
