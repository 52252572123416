import { motion } from "framer-motion";
import React from "react";
function Logo({ name }) {
  return (
    <motion.div
      style={{ fontFamily: "poppins", fontSize: 20 }}
      className="first-letter:font-black text-white first-letter:text-2xl z-20 font-thin select-none"
    >
      Sid's <span className=" underline text-sky-50 underline-offset-4">{name}</span>
    </motion.div>
  );
}

export default Logo;
