import React from 'react'

function Animals() {
  return (
    <div
    id="animals"
    className="h-screen md:p-20 flex flex-row justify-between text-white">
    Animals
    </div>
  )
}

export default Animals