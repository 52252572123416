import * as React from "react";
import { Helmet } from "react-helmet";
import Homepage from "../components/Welcome";
import "@fontsource/lato";
import "@fontsource/open-sans";
import "@fontsource/poppins";
import "@fontsource/jetbrains-mono";
import "../css/index.css";
import Sidebar from "../components/sidebar";
import { useViewportScroll, AnimatePresence } from "framer-motion";
import Hobbies from "../components/Hobbies";
import Animals from "../components/Animals";
import Contact from "../components/Contact";
// markup
const IndexPage = () => {
  const { scrollYProgress } = useViewportScroll();
  var [progress, updateProgress] = React.useState(0);
  scrollYProgress.onChange((v) => {
    updateProgress(v);
  });
  return (
    <AnimatePresence>
      <div className="bg-black ">
        <Helmet>
          <title>Hi there</title>
        </Helmet>
        <Homepage />
        <Hobbies />
        <Animals />
        <Contact />
        <Sidebar viewPortYScroll={progress} />
      </div>
    </AnimatePresence>
  );
};

export default IndexPage;
